@use '@angular/material' as mat;

$my-typography: mat.define-typography-config(
  // inherit font family from tailwindcss
  $font-family: 'inherit'
);
@include mat.core($my-typography);

// define a theme
$my-primary: mat.define-palette(mat.$indigo-palette);
$my-accent: mat.define-palette(mat.$pink-palette);
$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
    ),
  )
);

// a list of components that uses the theme
@include mat.core-theme($my-theme);
@include mat.button-theme($my-theme);
@include mat.menu-theme($my-theme);
@include mat.input-theme($my-theme);
@include mat.tooltip-theme($my-theme);
@include mat.form-field-theme($my-theme);
@include mat.checkbox-theme($my-theme);
@include mat.dialog-theme($my-theme);
@include mat.tabs-theme($my-theme);
@include mat.sidenav-theme($my-theme);

.mat-form-field {
  input:focus {
    box-shadow: none;
  }
  textarea:focus {
    box-shadow: none;
  }
}

.mat-menu-flat {
  .mat-menu-content {
    padding: 0 !important;
  }
}
