.wrapper {
  @apply m-auto sm:p-4 lg:p-6;
}

.panel {
  @apply bg-white overflow-hidden shadow flex flex-col w-full px-3 pt-4 pb-2 md:px-8 md:pt-6 md:pb-4 sm:rounded-lg sm:px-4 sm:pt-3;
}

.dialog-container {
  width: 600px;
}
