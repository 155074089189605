/* tailwindcss base (reset) */
@tailwind base;

/* setup material theme */
@import 'material-theme';

/* tailwindcss components & utilities needs to have higher specificity than material */

@tailwind components;
@tailwind utilities;

@import 'common/inputs';
@import 'common/utilities';
@import 'common/buttons';
@import 'common/headings';

// FORM
@import 'form';

.min-height-with-top {
  height: calc(100vh - 64px);
}
